import React from "react";

import { makeStyles } from "@mui/styles";

import {
  DashboardParcelTo,
  DashboardSeasonTo,
  InternalCropTo,
} from "../../../../../generated/api/satellite";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";
import { SelectObject } from "../../../../../shared/components/form/FormSelect/types";
import { YieldCell } from "../../../../components/yield/YieldCell";
import { useDelayedLoader } from "../../../../hooks/useDelayedLoader";
import { useUpdateSeasonParcelMutation } from "../../api/dashboard.api";
import { CropCell } from "../crops/CropCell";

import { SeasonCellIconBar } from "./SeasonCellIconBar";

type Props = {
  parcel: DashboardParcelTo;
  cropsOptions: SelectObject<InternalCropTo>[];
  season: DashboardSeasonTo;
  hasAssignedParcel: boolean;
};

const SeasonCell = ({
  cropsOptions,
  hasAssignedParcel,
  parcel,
  season,
}: Props) => {
  const classes = useStyles();
  const loaderClasses = useLoaderStyles();
  const yieldClasses = useYieldStyles();

  const seasonParcelMutation = useUpdateSeasonParcelMutation();

  const { isDelayed } = useDelayedLoader(seasonParcelMutation.isPending, 600);

  let Jsx = null;

  if (isDelayed) {
    Jsx = <CfLoader classes={loaderClasses} size={32} />;
  } else {
    Jsx = (
      <>
        <CropCell
          cropsOptions={cropsOptions}
          parcel={parcel}
          season={season}
          seasonParcelMutation={seasonParcelMutation}
        />
        <YieldCell
          classes={yieldClasses}
          handleMutation={seasonParcelMutation.mutate}
          isPending={seasonParcelMutation.isPending}
          parcel={parcel}
          seasonId={season.seasonId}
        />
      </>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>{Jsx}</div>
      {hasAssignedParcel && (
        <SeasonCellIconBar
          data={parcel}
          products={parcel.products ?? []}
          season={season}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  header: {
    width: "100%",
  },
}));

const useYieldStyles = makeStyles(() => ({
  formControl: {
    maxWidth: "14ch",
  },
  input: {
    textAlign: "end",
    height: 17,
  },
  placeholder: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    cursor: "pointer",
    height: 26,
    textAlign: "left",
    maxWidth: 228,
    minWidth: "25%",
    borderRadius: 8,
    fontSize: 12,
  },
}));

const useLoaderStyles = makeStyles(() => ({
  wrapper: {
    justifyContent: "flex-start",
  },
}));

export { SeasonCell };
