import React, { useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { getIndex } from "../../selectors/monitoring.selectors";

import {
  BiomonitoringIndexType,
  PrecisionFarmingProduct,
  SeasonWithCropTo,
} from "../../../../generated/api/satellite";
import { useFarmIds } from "../../../../shared/api/client.utils";
import { BioMonitoringHistoryInfoDialog } from "../../components/BioMonitoring/BioMonitoringHistoryInfoDialog";
import BioMonitoringImageErrorService from "../../services/BioMonitoringImageError.services";
import { ChartDataOrMonitoringData } from "../BioMonitoring";
import {
  biomonitoringDataSaviQuery,
  cropIntervalsQuery,
} from "../Biomonitoring.api";

import { MonitorIntervalType } from "./BiomonitoringWrapper/BiomonitoringWrapperV2";
import { isPlanetIndexType } from "./BiomonitoringWrapper/helpers";
import { IndexTypeDataV2Type } from "./types";
import { useLocking } from "./useLocking";

type Props = {
  selectedInterval?: MonitorIntervalType;
  parcelId: string;
  seasonId?: string;
  setCropIntervals: (val: SeasonWithCropTo[]) => void;
  setCropIntervalsIsFetching: (val: boolean) => void;
};

const useSaviBioMonitoringV2 = ({
  parcelId,
  seasonId,
  selectedInterval,
  setCropIntervals,
  setCropIntervalsIsFetching,
}: Props) => {
  const index = useSelector(getIndex);
  const dateFrom = selectedInterval?.dateFrom;

  const farmIds = useFarmIds();
  const biomonitoringData = useQuery(
    biomonitoringDataSaviQuery(parcelId, {
      farmIds: farmIds.farmIds,
      "season-id": seasonId,
      "index-type": index as BiomonitoringIndexType,
    }),
  );

  const fetchedData = biomonitoringData.data?.data;

  const cropIntervalsData = useQuery(
    cropIntervalsQuery(parcelId, {
      farmIds: farmIds.farmIds,
      product: PrecisionFarmingProduct.PLANET_BIOMONITORING_SAVI,
    }),
  );

  const cropIntervals = cropIntervalsData.data?.data ?? [];
  const cropIntervalsIsFetching =
    cropIntervalsData.isPending ||
    cropIntervalsData.isFetching ||
    cropIntervalsData.isLoading;

  useEffect(() => {
    setCropIntervalsIsFetching(cropIntervalsIsFetching);
  }, [cropIntervalsIsFetching, setCropIntervalsIsFetching]);

  useEffect(() => {
    setCropIntervals(cropIntervals);
  }, [cropIntervals, setCropIntervals]);

  const { isInit } = useLocking({
    isInitCustomCondition: isPlanetIndexType(index),
    cropIntervals,
    selectedIntervalId: selectedInterval?.selectedIntervalId,
    isFetchingCropIntervals: cropIntervalsIsFetching,
  });

  useEffect(() => {
    if (!isInit) {
      cropIntervalsData.refetch();
    }
  }, [isInit]);

  useEffect(() => {
    if (isInit && dateFrom) {
      biomonitoringData.refetch();
    }
  }, [dateFrom, isInit]);

  const getChartValue = (average?: number) => {
    if (average === undefined || average === null || average >= 0) {
      return average;
    } else {
      return 0;
    }
  };

  const data: ChartDataOrMonitoringData[] = (fetchedData?.records ?? []).map(
    (r) =>
      ({
        value: getChartValue(r.average),
        // tooltipValue: r.average,
        average: r.average,
        // average: -1,
        crop: undefined,
        dateFrom: r.dateFrom,
        dateTo: r.dateTo,
        imgExtent: r.imgExtent,
        indexType: r.type,
        imgPath: r.rasterUrl,
        zones: r.zones.map((z) => ({
          color: z.color,
          geometry: {
            type: z.geometry?.type,
            coordinates: z.geometry?.coordinates,
            geometries: z.geometry?.geometries,
          },
          zoneNumber: Number(z.index) || z.index,
          areaHa: -1,
          zoneValue: -1,
        })),
      }) as ChartDataOrMonitoringData,
  );

  const overallImagesError =
    BioMonitoringImageErrorService.getOverallImagesError(data, index);
  const cropHarvestedError =
    BioMonitoringImageErrorService.checkCropHarvestedError(data);
  const lastImageDateTo = fetchedData?.validity;

  const infoDialogContent = isPlanetIndexType(index) ? (
    <BioMonitoringHistoryInfoDialog.Root index={index}>
      <BioMonitoringHistoryInfoDialog.Footnote>
        <FormattedMessage
          id={`BioMonitoringInfoDialog.zoningExplanation.footnote.${index}`}
        />
      </BioMonitoringHistoryInfoDialog.Footnote>
    </BioMonitoringHistoryInfoDialog.Root>
  ) : undefined;

  const isFetching =
    biomonitoringData.isLoading ||
    biomonitoringData.isPending ||
    biomonitoringData.isFetching ||
    cropIntervalsIsFetching;

  return {
    isFetching,
    overallImagesError,
    cropHarvestedError,
    lastImageDateTo,
    data,
    infoDialogContent,
    graphData: [...data].sort((a, b) =>
      moment(a.dateFrom).diff(moment(b.dateFrom)),
    ),
  } as IndexTypeDataV2Type;
};

export { useSaviBioMonitoringV2 };
