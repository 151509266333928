import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import {
  DashboardSeasonTo,
  deleteParcelFromSeason,
  getSeasonDashboard,
  GetSeasonDashboardParams,
  updateSeasonParcelMetadata,
} from "../../../../generated/api/satellite";
import {
  getCountedData,
  useFarmIds,
} from "../../../../shared/api/client.utils";
import { staleTime } from "../../../../shared/query-client";
import { SeasonParcelMutationPayloadType } from "../../../types";

import { apiKeys } from "./apiKeys";

const servicesQuery = (seasonId: string, params: GetSeasonDashboardParams) =>
  queryOptions({
    queryKey: apiKeys.parcels.list(seasonId, params),
    queryFn: async () => {
      const data = await getSeasonDashboard(seasonId, params);
      return getCountedData(data);
    },
    staleTime: staleTime.Infinity,
  });

const useServicesDelete = () =>
  useMutation({
    mutationFn: (params: Parameters<typeof deleteParcelFromSeason>) =>
      deleteParcelFromSeason(...params),
  });

const useUpdateSeasonParcelMutation = () => {
  const farmIds = useFarmIds();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: SeasonParcelMutationPayloadType) =>
      updateSeasonParcelMetadata(payload, farmIds),
    onMutate: (params) => {
      const snapshot = queryClient.getQueriesData({
        predicate: (query) =>
          query.queryKey.includes(apiKeys.parcels.listUniqueKey()),
      });

      if (!snapshot || !snapshot.length || !snapshot[0].length) {
        throw new Error("No queries found with the specified predicate");
      }

      const targetKey = snapshot[0][0];

      queryClient.setQueryData(
        targetKey,
        (prev: { data: DashboardSeasonTo[]; count: number }) => {
          const resData = prev.data.map((item) => ({
            ...item,
            parcels: item.parcels.map((parcel) =>
              parcel.parcelId === params.parcelId
                ? {
                    ...parcel,
                    cropName: params.cropName,
                    cropInternalId: params.cropInternalId,
                    yieldInTonnes: params.yieldInTonnes,
                  }
                : parcel,
            ),
          }));

          return {
            ...prev,
            data: resData,
          };
        },
      );

      return () => {
        queryClient.setQueryData(targetKey, snapshot);
      };
    },
    onError: (error, variables, rollback) => {
      rollback?.();
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes(apiKeys.parcels.listUniqueKey()),
      }),
  });
};

export { servicesQuery, useServicesDelete, useUpdateSeasonParcelMutation };
