import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import EastIcon from "@mui/icons-material/East";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import WestIcon from "@mui/icons-material/West";
import { Theme, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";

import { PRECISION_URLS } from "../../../core/precision/precision.constants";
import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";

import CfTextFieldFilter from "../../../common/components/CfTextFieldFilter/CfTextFieldFilter";
import NoPrecision from "../../../core/precision/containers/NoPrecision";
import { useFarmIds } from "../../../shared/api/client.utils";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import { AdvancedFilter } from "../../components/advancedFilter/AdvancedFilter";
import { TableFooter } from "../../components/TableFooter";
import { HistoryPrevState } from "../../types";
import { useHistoryPreviousState } from "../../useHistoryPreviousState";
import { useCropOptions } from "../services/hooks/useCropOptions";

import { tableDataQuery } from "./api/dashboard.api";
import { ArrowButton } from "./components/arrowButton/ArrowButton";
import { Table } from "./components/table/Table";
import { useTablePaging } from "./components/table/useTablePaging";
import { useCustomizedUrlParams } from "./hooks/useCustomizedUrlParams";
import { usePageParams } from "./hooks/usePageParams";
import { NewSeasonForm } from "./NewSeasonForm";

const hasPages = (totalCount: number, perPageCount: number) =>
  Math.ceil(totalCount / perPageCount) > 1;

const isOffsetEnd = (
  totalCount: number,
  currentOffset: number,
  perPageCount: number,
): boolean => {
  const currentIsLast = currentOffset + perPageCount >= totalCount;
  return currentIsLast;
};

type Props = {
  langId: LANGUAGE_ID;
};

const Dashboard = ({ langId }: Props) => {
  const tableClasses = useStyles();
  const toolbarClasses = useToolbarStyles();

  const { farmIds } = useFarmIds();
  const { previousLocation } = useHistoryPreviousState();
  const location = useLocation();

  const hasPrecision = true;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { crops } = useCropOptions();
  const {
    parcelsPage,
    parcelsPerPage,
    seasonsOffset,
    seasonsPerPage,
    setAdvancedFilter,
    setParcelsPage,
    setParcelsPerPage,
    setSeasonsOffset,
  } = usePageParams();

  const { advancedFilter, setTextFilter, textFilter } = useCustomizedUrlParams({
    crops,
  });

  useTablePaging();

  const filteredCrops = advancedFilter?.crops?.map((c) => c.id);

  const [isLoadingSeasonsOffsetWest, setIsLoadingSeasonsOffsetWest] =
    useState(false);
  const [isLoadingSeasonsOffsetEast, setIsLoadingSeasonsOffsetEast] =
    useState(false);

  const {
    data: dashboard,
    error,
    isFetching,
    isLoading,
  } = useQuery(
    tableDataQuery(
      {
        farmIds,
        "per-page": parcelsPerPage,
        "sort-dir": "asc",
        page: parcelsPage + 1,
        search: textFilter,
        "seasons-offset": seasonsOffset,
        "seasons-per-page": seasonsPerPage,
        crops: filteredCrops,
      },
      isLoadingSeasonsOffsetWest || isLoadingSeasonsOffsetEast,
    ),
  );

  useEffect(() => {
    if (!isFetching) {
      if (isLoadingSeasonsOffsetWest) {
        setIsLoadingSeasonsOffsetWest((prev) => !prev);
      }
      if (isLoadingSeasonsOffsetEast) {
        setIsLoadingSeasonsOffsetEast((prev) => !prev);
      }
    }
  }, [isFetching, isLoadingSeasonsOffsetWest, isLoadingSeasonsOffsetEast]);

  const dashboardData = dashboard?.data;
  const parcelsCount = dashboard?.parcelsCount ?? 0;
  const seasonsCount = dashboard?.seasonsCount ?? 0;

  const toggleDrawer =
    (open: boolean) => (event?: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setIsDrawerOpen(open);
    };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setParcelsPerPage(newRowsPerPage);
    setParcelsPage(0);
  };

  let Jsx = null;

  if (hasPrecision !== undefined && !hasPrecision) {
    Jsx = <NoPrecision langId={langId} />;
  } else {
    const hasColumnPages =
      !isFetching && hasPages(seasonsCount, seasonsPerPage);
    const isLastSeasonPage = isOffsetEnd(
      seasonsCount,
      seasonsOffset,
      seasonsPerPage,
    );

    const dashboardParcels = dashboardData?.parcels ?? [];
    const dashboardSeasons = dashboardData?.seasons ?? [];
    const noParcelsForSeasons = !!(
      dashboardSeasons.length && !dashboardParcels.length
    );

    Jsx = (
      <div>
        <div className={toolbarClasses.header}>
          <Button
            className={toolbarClasses.backToDetail}
            component={Link}
            size="large"
            variant="text"
            sx={{
              textTransform: "unset",
            }}
            to={{
              pathname:
                previousLocation?.pathname ||
                `/farm/${farmIds}/${PRECISION_URLS.seasons()}`,
              search: previousLocation?.search,
              state: {
                previousLocation: { ...location },
              } as HistoryPrevState,
            }}
          >
            <span className={toolbarClasses.button}>
              <KeyboardArrowLeftIcon sx={{ mr: 1 }} />
              <FormattedMessage id="PrecisionFarming.seasons.backToList" />
            </span>
          </Button>
          <div className={toolbarClasses.toolbar}>
            <div className={toolbarClasses.leftSide}>
              <CfTextFieldFilter
                initialValue={textFilter}
                name="precision-list-text-filter"
                onChange={setTextFilter}
                translId="PrecisionFarming.seasons.table.filter"
              />
              <AdvancedFilter
                advancedFilter={advancedFilter}
                crops={crops}
                setAdvancedFilter={setAdvancedFilter}
              />
            </div>
            <Button
              color="secondary"
              onClick={toggleDrawer(true)}
              size="small"
              style={{ minHeight: 48 }}
              variant="outlined"
              classes={{
                root: toolbarClasses.addSeasonRoot,
              }}
              sx={{
                textTransform: "unset",
                padding: "11px 16px",
              }}
            >
              <span className={toolbarClasses.button}>
                <AddIcon sx={{ mr: 1 }} />
                <span className={toolbarClasses.addSeason}>
                  <FormattedMessage id="PrecisionFarming.seasons.addSeason.openButton" />
                </span>
              </span>
            </Button>
          </div>
        </div>
        <div className={tableClasses.tableWrapper}>
          <Table
            dashboardParcels={dashboardParcels}
            dashboardSeasons={dashboardSeasons}
            noParcelsForSeasons={noParcelsForSeasons}
            isLoading={
              isLoading &&
              !isLoadingSeasonsOffsetWest &&
              !isLoadingSeasonsOffsetEast
            }
            nextIcon={
              <ArrowButton
                disabled={isLastSeasonPage || !hasColumnPages}
                isLoading={isLoading}
                isLoadingOffset={isLoadingSeasonsOffsetEast}
                noParcelsForSeasons={noParcelsForSeasons}
                seasonsOffset={seasonsOffset}
                onClick={() => {
                  setIsLoadingSeasonsOffsetEast((prev) => !prev);
                  setSeasonsOffset(seasonsOffset + 1);
                }}
              >
                <EastIcon htmlColor="#FFFFFF" />
              </ArrowButton>
            }
            prevIcon={
              <ArrowButton
                disabled={!seasonsOffset || !hasColumnPages}
                isLoading={isLoading}
                isLoadingOffset={isLoadingSeasonsOffsetWest}
                noParcelsForSeasons={noParcelsForSeasons}
                seasonsOffset={seasonsOffset}
                onClick={() => {
                  setIsLoadingSeasonsOffsetWest((prev) => !prev);
                  setSeasonsOffset(seasonsOffset - 1);
                }}
              >
                <WestIcon htmlColor="#FFFFFF" />
              </ArrowButton>
            }
          />
          <div className={tableClasses.footerContainer}>
            {!isLoading && (
              <TableFooter
                count={parcelsCount}
                page={parcelsPage}
                rowsPerPage={parcelsPerPage}
                setPage={setParcelsPage}
                setRowsPerPage={handleRowsPerPageChange}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <CfErrorPage error={error ?? undefined}>
      <div className={tableClasses.wrapper}>
        {Jsx}
        <NewSeasonForm
          onCloseClick={toggleDrawer(false)}
          open={isDrawerOpen}
          page={parcelsPage}
        />
      </div>
    </CfErrorPage>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    height: "100%",
    position: "relative",
  },
  tableWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  footerContainer: {
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
  },
  hasNoColumnPagesArrow: {
    display: "none",
  },
}));

export const useToolbarStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    flexGrow: 1,
    width: "100%",
    gap: 16,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
  },
  backToDetail: {
    fontSize: 24,
    padding: "0 11px 0 0",
    borderRadius: 8,
    "&:focus": {
      outline: 0,
    },
  },
  addSeasonRoot: {
    borderRadius: 8,
  },
  addSeason: {
    lineHeight: 1.25,
    marginTop: 2,
    fontSize: 16,
    fontWeight: 500,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
    gap: 10,
  },
  leftSide: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch",
    gap: 12,
  },
}));

export { Dashboard };
