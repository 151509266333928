import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  styled,
  Table,
  TableBody,
} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useVirtualizer, Virtualizer } from "@tanstack/react-virtual";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import CfTextFilter from "../../../common/components/CfTextFilter/CfTextFilter";
import { useFarmIds } from "../../../shared/api/client.utils";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useToggle } from "../../../shared/hooks/useToggle";
import { AdvancedFilter } from "../../components/advancedFilter/AdvancedFilter";
import { Sidebar } from "../../components/sidebar/Sidebar";
import { AdvancedFilterType } from "../../types";
import { useCropOptions } from "../services/hooks/useCropOptions";

import {
  parcelsQuery,
  seasonQuery,
  useAddParcelsMutation,
} from "./ParcelImport.api";

const ParcelImport = () => {
  const { seasonId } = useParams<{ seasonId: string }>();
  invariant(seasonId, "seasonId page param is not defined");
  const [search, setSearch] = React.useState("");
  const [advancedFilter, setAdvancedFilter] =
    React.useState<AdvancedFilterType>({});
  const season = useSuspenseQuery(
    seasonQuery({
      seasonId,
      ...useFarmIds(),
    }),
  );
  const { crops } = useCropOptions();

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flex="1"
        flexDirection="column"
        gap={1}
        height="100%"
        minHeight={0}
        paddingX={2}
      >
        <SeasonName>
          <FormattedMessage id="Seasons.parcelImport.seasonTitle" />{" "}
          {season.data.data.name}
        </SeasonName>
        <p>
          <FormattedMessage id="Seasons.parcelImport.info" />
        </p>
        <Box display="flex" gap={2} width="100%">
          <CfTextFilter
            onChange={setSearch}
            sx={{ flex: 2, display: "flex" }}
            translId="Seasons.parcelImport.searchPlaceholder"
          />

          <AdvancedFilter
            advancedFilter={advancedFilter}
            crops={crops}
            setAdvancedFilter={setAdvancedFilter}
          />
        </Box>
        <ErrorBoundary
          fallbackRender={({ error }) => <CfErrorPage error={error} />}
        >
          <React.Suspense fallback={<CfLoader />}>
            <ParcelList
              crops={(advancedFilter.crops ?? []).map((c) => c.id)}
              search={search}
            />
          </React.Suspense>
        </ErrorBoundary>
      </Box>
    </>
  );
};

const Virtualized = ({
  children,
  items,
}: {
  children: (virtualizer: Virtualizer<Element, Element>) => React.ReactNode;
  items: number;
}) => {
  const parentRef = React.useRef(null);
  const rowVirtualizer = useVirtualizer({
    count: items,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
  });

  return (
    <Box height="100%" mb={3} overflow="auto" ref={parentRef} width="100%">
      <Box height={`${rowVirtualizer.getTotalSize()}px`} width="100%">
        {children(rowVirtualizer)}
      </Box>
    </Box>
  );
};

const ParcelList = ({ crops, search }: { crops: string[]; search: string }) => {
  const { seasonId } = useParams<{ seasonId: string }>();
  invariant(seasonId, "seasonId page param is not defined");
  const parcels = useSuspenseQuery({
    ...parcelsQuery({
      ...useFarmIds(),
      "season-id": seasonId,
      search,
      crops,
    }),
  });
  const { isEmpty, isSelected, selectAll, selected, toggle, unselect } =
    useSelection(
      parcels.data.data.filter((p) => !p.isSeasonMember).map((p) => p.parcelId),
    );
  const showSnackbar = React.useContext(SnackbarContext);
  const queryClient = useQueryClient();

  const addParcelsMutation = useAddParcelsMutation();

  const handleSubmit = () => {
    addParcelsMutation.mutate(
      {
        seasonId,
        parcelIds: selected,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          close();
        },
        onError: () => {
          showSnackbar({
            message: <FormattedMessage id="common.changesSaved.error" />,
            isError: true,
          });
        },
      },
    );
  };

  return (
    <>
      {parcels.data.data.length > 0 ? (
        <>
          <BulkSelect onClick={isEmpty ? selectAll : unselect} variant="text">
            <FormattedMessage id="Seasons.parcelImport.selectAllParcels" />
          </BulkSelect>
          <Virtualized items={parcels.data.data.length}>
            {(virtualizer) => (
              <Table>
                <TableBody>
                  {virtualizer.getVirtualItems().map((virtualItem, i) => {
                    const parcel = parcels.data.data[virtualItem.index];
                    return (
                      <TableRow
                        hover
                        key={parcel.parcelId}
                        selected={isSelected(parcel)}
                        sx={{
                          boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.10)",
                          height: `${virtualItem.size}px`,
                          transform: `translateY(${
                            virtualItem.start - i * virtualItem.size
                          }px)`,
                        }}
                      >
                        <CfTableCell name="id" sx={{ border: "none" }}>
                          <Checkbox
                            checked={isSelected(parcel)}
                            color="primary"
                            disabled={parcel.isSeasonMember}
                            disableRipple
                            name={parcel.parcelId}
                            onChange={() => toggle(parcel.parcelId)}
                          />
                        </CfTableCell>
                        <CfTableCell name="name" sx={{ border: "none" }}>
                          <Box display="flex" flexDirection="column" gap="2px">
                            <ParcelName>{parcel.localName}</ParcelName>
                            {parcel.area ? (
                              <ParcelArea>
                                <FormattedNumber value={parcel.area} /> ha
                              </ParcelArea>
                            ) : (
                              "-"
                            )}
                          </Box>
                        </CfTableCell>
                        <CfTableCell name="code" sx={{ border: "none" }}>
                          {parcel.blockNr}
                        </CfTableCell>
                        <CfTableCell name="crop" sx={{ border: "none" }}>
                          {parcel.cropName}
                        </CfTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </Virtualized>
          <SubmitButton
            color="primary"
            disabled={isEmpty || addParcelsMutation.status === "pending"}
            onClick={handleSubmit}
            variant="contained"
          >
            {addParcelsMutation.status === "pending" ? (
              <CfLoader size={20} sx={{ "&.MuiBox-root": { width: "auto" } }} />
            ) : null}
            <span>
              <FormattedMessage id="Seasons.parcelImport.addParcel" />
            </span>
          </SubmitButton>
        </>
      ) : (
        <span>
          <FormattedMessage id="common.empty-table" />
        </span>
      )}
    </>
  );
};
const useClose = () => {
  const history = useHistory();

  const close = () => {
    history.push("..");
  };

  return close;
};

const useSelection = (ids: string[]) => {
  const [selected, setSelected] = React.useState<typeof ids>([]);
  const isEmpty = selected.length === 0;

  const toggle = (id: string) =>
    setSelected((current) =>
      current.includes(id) ? current.filter((v) => v !== id) : [...current, id],
    );

  const selectAll = () => {
    setSelected(ids);
  };

  const unselect = () => {
    setSelected([]);
  };

  const isSelected = (parcel: { parcelId: string; isSeasonMember: boolean }) =>
    selected.includes(parcel.parcelId) || parcel.isSeasonMember;

  return {
    isEmpty,
    selected,
    toggle,
    select: setSelected,
    selectAll,
    unselect,
    isSelected,
  };
};

const Heading = ({ onClose }: { onClose: () => void }) => (
  <Box display="flex" position="relative" width="100%">
    <IconButton
      aria-label="close"
      onClick={onClose}
      size="small"
      sx={{ position: "absolute", left: 0 }}
    >
      <CloseIcon />
    </IconButton>
    <h1 style={{ marginBottom: 0, width: "100%", textAlign: "center" }}>
      <FormattedMessage id="Seasons.parcelImport.pageTitle" />
    </h1>
  </Box>
);

const SubmitButton = styled(Button)(
  ({ theme }) => `
    width: calc(100% - ${theme.spacing(4)});
    display: flex;
    gap: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
    margin-left: auto;
    margin-right: auto;
`,
);

const BulkSelect = styled(Button)(
  ({ theme }) => `
  color: ${theme.palette.primary.main};
  padding: 0;
  align-self: flex-start;
  text-transform: none;
  width: fit-content;
  margin-top: ${theme.spacing(1)};
  &:hover {
    background: transparent
  }
`,
);

const ParcelArea = styled("small")`
  font-size: 12px;
`;

const ParcelName = styled("strong")(
  ({ theme }) => `
  color: ${theme.palette.primary.main};
`,
);

const SeasonName = styled("h2")(
  ({ theme }) => `
  color: ${theme.palette.grey[600]};
  font-size: 16px;
  margin-top: 0;
`,
);

const ParcelImportTree = () => {
  const { on: isOpen, setOff: close, setOn: open } = useToggle();
  const closeRoute = useClose();

  React.useEffect(() => {
    open();
  }, [open]);

  const handleClose = () => {
    close();
    setTimeout(closeRoute, 100);
  };

  return (
    <Sidebar
      open={isOpen}
      sx={{ minWidth: { xs: "100%", sm: "600px" }, width: { sm: "600px" } }}
    >
      <Box paddingTop={2} paddingX={2}>
        <Heading onClose={handleClose} />
      </Box>
      <ErrorBoundary
        fallbackRender={({ error }) => (
          <CfErrorPage
            error={error}
            error40xHeadingTranslId="error.heading.40x"
            error40xMessageTranslId="error.message.40x"
            handle400s
          />
        )}
      >
        <React.Suspense fallback={<CfLoader />}>
          <ParcelImport />
        </React.Suspense>
      </ErrorBoundary>
    </Sidebar>
  );
};

export { ParcelImportTree };
