import React, { useState } from "react";

import { Box, Button, ButtonProps, styled, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";
import { isNil } from "lodash";
import { FormattedMessage } from "react-intl";
import { Link, LinkProps, useLocation, useRouteMatch } from "react-router-dom";

import { PRECISION_URLS } from "../../../core/precision/precision.constants";
import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";

import SeasonOverviewIcon from "../../../assets/img/icons/satellite/precision/services/season-overview.svg";
import CfTextFieldFilter from "../../../common/components/CfTextFieldFilter/CfTextFieldFilter";
import NoPrecision from "../../../core/precision/containers/NoPrecision";
import { usePrecisionListStyles } from "../../../core/precision/containers/styles";
import { useIsPrecisionFarmingActive } from "../../../core/precision/hooks/useIsPrecisionFarmingActive";
import { SeasonTo } from "../../../generated/api/satellite";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import { SelectObject } from "../../../shared/components/form/FormSelect/types";
import useWidth from "../../../shared/hooks/useWidth";
import { RsaaApiError } from "../../../types";
import { AdvancedFilter } from "../../components/advancedFilter/AdvancedFilter";
import { HistoryPrevState } from "../../types";
import { useHistoryPreviousState } from "../../useHistoryPreviousState";
import { seasonsQuery } from "../dashboard/api/dashboard.api";

import { SeasonDropdown } from "./components/SeasonDropdown";
import { TotalArea } from "./components/TotalArea";
import { useCropOptions } from "./hooks/useCropOptions";
import { useCustomizedUrlParams } from "./hooks/useCustomizedUrlParams";
import { usePageParams } from "./hooks/usePageParams";
import { useSeason } from "./hooks/useSeason";
import Table from "./table/Table";

type Props = {
  error?: RsaaApiError;
  farmId: string;
  langId: LANGUAGE_ID;
};

const PrecisionServicesNew = ({
  children,
  error,
  farmId,
  langId,
}: React.PropsWithChildren<Props>) => {
  const match = useRouteMatch();
  const pzListClasses = usePrecisionListStyles();
  const classes = useStyles();

  const width = useWidth();
  const isMobile = width === "xs" || width === "sm";
  const { previousLocation } = useHistoryPreviousState();
  const { setAdvancedFilter } = usePageParams();

  const { crops, cropsOptions } = useCropOptions();

  const { advancedFilter, setTextFilter, textFilter } = useCustomizedUrlParams({
    crops,
  });

  const location = useLocation();

  const [totalArea, setTotalArea] = useState(0);

  const hasPrecision = useIsPrecisionFarmingActive({ isPageInitCall: false });

  const seasons = useQuery(
    seasonsQuery({
      farmIds: farmId,
      "sort-dir": "desc",
    }),
  );
  const fetchedSeasons = seasons?.data?.data;
  const seasonsOptions: SelectObject<SeasonTo>[] =
    fetchedSeasons?.map((s) => ({
      key: s.id,
      value: s.name,
      obj: s,
    })) ?? [];

  const { handleSeasonChange, season } = useSeason({
    isInit: !!(seasons.isSuccess && seasonsOptions.length),
    seasonsOptions,
    farmId,
  });

  let Jsx = <CfLoader />;

  if (hasPrecision !== undefined && !hasPrecision) {
    Jsx = <NoPrecision langId={langId} />;
  }

  if (hasPrecision && !isNil(season) && !isNil(season.obj)) {
    Jsx = (
      <div>
        <div className={classes.toolbar}>
          <div className={classes.season}>
            <SeasonDropdown
              seasonsOptions={seasonsOptions}
              selected={season}
              setSelected={handleSeasonChange}
            />
          </div>
          <div className={classes.toolbarSubrow}>
            <div className={classes.toolbarSubrowLeft}>
              <TotalArea totalArea={totalArea} />
              <CfTextFieldFilter
                initialValue={textFilter}
                name="precision-list-text-filter"
                onChange={setTextFilter}
                translId="PrecisionServices.table.textFilter"
                style={
                  !isMobile
                    ? {
                        width: "100%",
                        minWidth: 340,
                      }
                    : undefined
                }
              />
              <AdvancedFilter
                advancedFilter={advancedFilter}
                crops={crops}
                setAdvancedFilter={setAdvancedFilter}
              />
            </div>
            <Box display="flex" gap={2}>
              <Action
                color="secondary"
                component={Link}
                size="large"
                to={`${match.url}/parcel-import`}
                variant="outlined"
              >
                +{" "}
                <FormattedMessage id="PrecisionFarming.seasons.parcelImport" />
              </Action>
              <Action
                color="secondary"
                component={Link}
                size="large"
                variant="outlined"
                to={{
                  pathname:
                    previousLocation?.pathname ||
                    `/farm/${farmId}/${PRECISION_URLS.seasonsDashboard()}`,
                  search: previousLocation?.search,
                  state: {
                    previousLocation: { ...location },
                  } as HistoryPrevState,
                }}
              >
                <span className={classes.seasonOverviewBody}>
                  <img alt="season overview" src={SeasonOverviewIcon} />
                  <span style={{ lineHeight: 1.25, marginTop: 2 }}>
                    <FormattedMessage id="PrecisionFarming.seasons.backToDashboard" />
                  </span>
                </span>
              </Action>
            </Box>
          </div>
        </div>
        <Table
          advancedFilter={advancedFilter}
          cropsOptions={cropsOptions}
          farmId={farmId}
          season={season.obj}
          setTotalArea={setTotalArea}
          textFilter={textFilter}
        />
      </div>
    );
  }

  return (
    <CfErrorPage error={error}>
      <div className={pzListClasses.wrapper}>{Jsx}</div>
      {children}
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    paddingBottom: theme.spacing(2),
    flexWrap: "wrap",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
    },
  },
  toolbarSubrow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    gap: 16,
    flexWrap: "wrap",
  },
  toolbarSubrowLeft: {
    display: "flex",
    alignItems: "stretch",
    gap: 16,
    flexWrap: "wrap",
  },
  seasonOverviewBody: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  season: {
    marginBottom: 20,
  },
}));

const Action = styled(Button)<ButtonProps & LinkProps>(
  ({ theme }) => `
    text-transform: unset;
    padding: 11px 16px;
    font-size: 16;
    border-radius: 8px;
    color: ${theme.palette.secondary.main};
    border-color: ${theme.palette.secondary.main};
    &.MuiButton-root:hover,&.MuiButton-root:focus {
      outline: 0;
      color: ${theme.palette.secondary.main};
      text-decoration: none;
    }
`,
);

export { PrecisionServicesNew };
